import { compose, withHooks } from "enhancers"
import { Box, Select, TextButtonWithCreateModal, TextButtonWithEditModal } from "components"
import { gql } from "utils/helper"
import { relation } from "constants/objects/relation"
import { isEmpty } from "lodash"

const RelationSelect = (props: any) => (
  <>
    <Select {...props} />
    {props.showExtraSection && (
      <Box display="flex" flexDirection="column" marginTop="16px">
        <TextButtonWithCreateModal {...props} />
        {props.isShow && (
          <>
            <br />
            <TextButtonWithEditModal {...props} />
          </>
        )}
      </Box>
    )}
  </>
)

const API = {
  GET_DEPENDENTS: gql`
    query GET_DEPENDENTS($relationships: [String!]!, $requesterId: String!, $filterOptions: JSON!) {
      userDependents(
        input: { relationships: $relationships, requesterId: $requesterId, filterOptions: $filterOptions }
      ) {
        id
        name
        relation
        birthDate
      }
    }
  `,
}

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { options = [], requester, form, filterOptions = {}, showCreateOrEditSection } = props
    const { setFieldValue } = form || {}
    const { useMemo, useQuery, useEffect } = hooks
    const { data: dependents, refetch: refetchDependents } = useQuery(API.GET_DEPENDENTS, {
      variables: { relationships: options || [], requesterId: requester?.id, filterOptions },
    })

    const customOptions = useMemo(() => {
      const response = dependents?.userDependents || []

      const familyOptions = response.map((dependent: any) => ({
        label: `[${relation[dependent.relation as keyof typeof relation]}] ${dependent.name}`,
        value: dependent.relation === "self" ? dependent.id : {
          id: dependent.id,
          name: dependent.name,
          relation: dependent.relation
        },
      }))

      return familyOptions
    }, [dependents?.userDependents])

    const customHelperText = useMemo(() => {
      if (isEmpty(customOptions)) {
        return "ไม่มีข้อมูลครอบครัวกรุณาเพิ่มข้อมูลก่อนดำเนินการต่อ"
      }
      return ""
    }, [customOptions])

    const showEditButton = useMemo(() => {
      if (isEmpty(customOptions)) {
        return false
      }
      const filterSelfOut = customOptions.filter((option: any) => option.value !== "self")
      return filterSelfOut.length > 0
    }, [customOptions])

    useEffect(() => {
      setFieldValue("userDependent", dependents?.userDependents)
    }, [dependents?.userDependents, setFieldValue])

    return {
      ...props,
      options: customOptions,
      allUserDependents: dependents?.userDependents || [],
      helperText: customHelperText,
      refetchDependents: refetchDependents,
      isShow: showEditButton,
      showExtraSection: showCreateOrEditSection,
    }
  }),
)

export default enhancer(RelationSelect)
